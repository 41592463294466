import React from "react";
import Layout from "../components/layout";

const SearchResults = () => {
    return (
        <Layout title="Search">
        <div class="gcse-searchresults-only"></div>
        </Layout>
    )
}

export default SearchResults;